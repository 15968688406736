.work-search-text-container {
  flex: 1 1 auto;
  align-self: flex-end;
  //overflow: hidden;
}

.works-list-container {

  .work-list-item,
  .work-list-item2 {

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: auto;
    align-content: flex-start;
    height: auto;
    overflow: hidden;
    animation-fill-mode: both;


    .work-spacer-scroll-line {
      width: 100%;
      padding-top: .5em;
      padding-bottom: .5em;

      .center-line {
        border-top: 1px solid black;
      }
    }

    .work-list-image-container {
      max-width: 9em;
      max-height: 4.9em;
      flex: 1 1 auto;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        opacity: 0;
        display: block;
        margin: 0;
        height: 100%;
      }

      img.loaded {
        transition: 2s;
        opacity: 1;
      }

    }

    .work-content-container {
      width: 40%;
      flex: 4 4 auto;

    }

  }
}



.modal-video-container {
  transform: translateY(-100%);
  position: absolute;
  z-index: 90;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: auto;
  align-content: center;
  background-color: black;

  .modal-video-wrapper {
    background-color: black;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: center;
  }
}

.work-subtitle-preview-container {
  line-height: .8em;

  .work-list-line-preview-ico,
  .work-list-preview-ico {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: auto;
    align-content: center;
  }
}

.work-iconos-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: auto;
  align-content: center;
  line-height: .9em;
}

.x-close {
  cursor: pointer;
  color: white;
  font-size: 1.46em;
  position: absolute;
  z-index: 8000;
  right: 0;
}


.search-box-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: auto;
  align-content: flex-start;
  width: 100%;

  .ico-search {
    padding-right: .2em;
    transform: translateY(-.15em);
  }

  .work-search {
    border: none;
    /* <-- This thing here */
    border: solid 1px #000;
    transform: translateY(-.3em);
    width: 200px;
    font-family: 'Montserrat', sans-serif;
    padding-left: .3em;
    right: inherit;
    font-weight: 400;
    font-size: .9em;
    letter-spacing: -0.02em;
  }

  .work-search:focus {
    outline: none;
  }

}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 15px 15px;
}

.section-title-text-container {
  overflow: hidden;
}

.section-title-liner {
  display: block;
  line-height: .5em;
  background-color: white;
}







/* animacion work left to right sin buscar */
@keyframes text-anim {
  0% {
    color: white;
    transform: translateX(0);
  }

  100% {
    transform: translateX(104);
  }
}



/* animacion work left to right sin buscar */
@keyframes my-animation {
  0% {
    transform: translateX(104%);
  }

  50% {
    transform: translateX(104%);
  }

  100% {
    transform: translateX(0);
  }
}

.work-list-item {
  animation: my-animation 1000ms ease-out;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      .work-line-container {
        animation: text-anim 500ms ease-out;

        &:nth-child(1) {
          animation-delay: (200ms * $i)+ 900ms;
          animation-fill-mode: both;
        }

        &:nth-child(2) {
          animation-delay: (200ms * $i)+ 1000ms;
          animation-fill-mode: both;
        }

        &:nth-child(3) {
          animation-delay: (200ms * $i)+ 1100ms;
          animation-fill-mode: both;
        }

        &:nth-child(4) {
          animation-delay: (200ms * $i)+ 1200ms;
          animation-fill-mode: both;
        }
      }

      animation-delay: 200ms * $i;
    }
  }
}

/* animacion work left to right buscador seleecionado */
@keyframes my-animation2 {
  0% {
    transform: translateX(104%);
  }

  100% {
    transform: translateX(0);
  }
}

.work-list-item2 {
  animation: my-animation2 500ms ease-out;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      animation-delay: 100ms * $i;
      animation-delay: .6s;
      animation-duration: 1s;
      animation-fill-mode: both;

      .work-line-container {
        animation: text-anim 500ms ease-out;

        &:nth-child(1) {
          animation-delay: (200ms * $i)+ 900ms;
          animation-fill-mode: both;
        }

        &:nth-child(2) {
          animation-delay: (200ms * $i)+ 1000ms;
          animation-fill-mode: both;
        }

        &:nth-child(3) {
          animation-delay: (200ms * $i)+ 1100ms;
          animation-fill-mode: both;
        }

        &:nth-child(4) {
          animation-delay: (200ms * $i)+ 1200ms;
          animation-fill-mode: both;
        }
      }
    }
  }
}