@keyframes taadaa {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

$button-size: 60px;
$blue: #3498db;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.loader-container {
  right: 50%;

  top: 50%;
  position: absolute;
  transform: translate(30px, -30px);

  .SimpleVideo-loader {
    z-index: 500;
    // animation: spin 1s linear infinite;
    //border-radius: 50%;
    //border: $button-size * 0.05 solid rgba(255, 255, 255, 0.8);
    // border-left-color: transparent;
    // color: transparent;
    content: '';
    display: block;
    height: $button-size * 0.9;


    width: $button-size * 0.9;
  }
}

.timer-container {
  position: absolute;
  z-index: +1;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: center;

  .timer-clock {
    font-size: 5em;
  }
}

.video-item {
  width: 100%;
  height: 100%;

  .video-player-item {
    max-height: 100%;
  }
}