.navbar-wrapper {
  position: absolute;
  z-index: 11;
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: flex-start;
  overflow: hidden;

  .navbar-menu-item {
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: auto;
    align-content: flex-start;

  }

}

@keyframes animNavbar {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-text {
  animation-name: animNavbar;
  animation-delay: 1s;
  animation-duration: 3s;
  animation-fill-mode: both;

}