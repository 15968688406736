@media (min-width: 280px) and (orientation: landscape) {
    .intro-anim-text {

        font-size: 3em;
        line-height: .85em;
        padding-left: .1em;
        padding-right: .1em;
    }

    .for-slide-title-text {

        text-transform: capitalize;
        font-size: 1em;
    }

}


@media (min-width: 280px) and (orientation: portrait) {

    .intro-anim-text {

        font-size: 3em;
        line-height: .85em;
        padding-left: .1em;
        padding-right: .1em;
    }

    .for-slide-title-text {
        text-transform: capitalize;
        font-size: 3em;
        /*  color: magenta;*/
    }
}