.home-motion-container {
  position: absolute;
  pointer-events: none;
  z-index: 70;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .home-motion-wrapper {
    // line-height: 4em;
    //letter-spacing: -.3em;
    transform: translateY(5%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: auto;
    align-content: center;
    overflow: hidden;

    .motion-text-item1 {
      transform: translateX(900%);
      overflow: hidden;
      align-self: center;

      .text-1 {
        transform: translateY(-100%);
      }
    }

    .motion-text-item1b {
      transform: translateX(900%);
      overflow: hidden;
      align-self: center;

      .text-1b {
        transform: translateY(-100%);
      }
    }

    .motion-text-item2 {
      transform: translateX(900%);
      overflow: hidden;
      align-self: center;

      .text-2 {
        transform: translateY(-100%);
      }
    }

    .motion-text-item2b {
      transform: translateX(900%);
      overflow: hidden;
      align-self: center;

      .text-2b {
        transform: translateY(-100%);
      }
    }

    .motion-text-item3 {
      transform: translateX(900%);
      overflow: hidden;
      text-align: center;
      padding-left: .05em;
      padding-right: .1em;
      // font-size: 4em; 
      //line-height: .8em; 
      background-color: white;
      flex: 0 1 auto;

      .text-3 {
        transform: translateY(-100%);
      }

    }

    .motion-text-item1,
    .motion-text-item2,
    .motion-text-item1b,
    .motion-text-item2b {
      background-color: white;
      //   font-size: 4em;
      flex: 0 0 auto;

    }

  }
}