  .section-workitem-wrapper {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: auto;
    align-content: center;
    overflow: hidden;

    .workitem-title-container,
    .workitem-content-container {
      text-align: center;
      overflow: hidden;
    }



    @media (min-width: 280) and (orientation: landscape) {
      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 1280px;
          max-height: 720px;
        }
      }
    }

    @media (min-width: 280px) and (orientation: portrait) {
      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 2em;
        }

        .detail-item-text-title {
          font-size: 2em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 280px;
          max-height: 158px;
        }
      }
    }

    @media (min-width: 576px) and (orientation: portrait) {

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 280px;
          max-height: 158px;
        }
      }
    }

    @media (min-width: 576px) and (orientation: landscape) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 2em;
        }

        .detail-item-text-title {
          font-size: 2em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 213px;
          max-height: 120px;
        }
      }
    }

    @media (min-width: 768px) and (orientation: portrait) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 3em;
        }

        .detail-item-text-title {
          font-size: 3em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 960px;
          max-height: 540px;
        }
      }
    }

    @media (min-width: 1024px) and (max-height: 700px) and (orientation: landscape) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 3em;
        }

        .detail-item-text-title {
          font-size: 3em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 498px;
          max-height: 280px;
        }
      }
    }

    @media (min-width: 1024px) and (min-height: 768px) and (orientation: landscape) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 3em;
        }

        .detail-item-text-title {
          font-size: 3em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 640px;
          max-height: 360px;
        }
      }
    }

    @media (min-width: 1200px) and (orientation: landscape) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 3em;
        }

        .detail-item-text-title {
          font-size: 3em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 640px;
          max-height: 360px;
        }
      }
    }

    @media (min-width: 1200px) and (min-height: 900px) and (orientation: landscape) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 3em;
        }

        .detail-item-text-title {
          font-size: 3em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 960px;
          max-height: 540px;
        }
      }
    }

    @media (min-width: 1920px) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 3em;
        }

        .detail-item-text-title {
          font-size: 3em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 960px;
          max-height: 540px;
        }
      }
    }

    @media (min-width: 2100px) {

      .detail-item-title-wrapper {
        //  border-bottom: 1px black solid;

        .detail-item-text-number {
          font-weight: 300;
          font-size: 3em;
        }

        .detail-item-text-title {
          font-size: 3em;
        }
      }

      .workitem-video-container {
        text-align: center;

        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 1280px;
          max-height: 720px;
        }
      }
    }



    .detail-subtitle-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: flex-start;

    }

    .item-spacer-scroll-line {
      width: 100%;
      line-height: .8;
    }

    .detail-item-pusher2 {
      display: block;
      //content: " placeholder ";
      //margin: 10px;
      flex: 888 888 auto;
    }

    .detail-item-pusher {
      display: block;
      //content: " placeholder ";
      //margin: 10px;
      flex: 555 555 auto;
    }


  }


  .x-close {
    cursor: pointer;
    font-weight: 300;
    font-size: .7em;
  }


  .item-item-iconos-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: flex-start;
  }

  .close-item-item {
    font-size: 1.5em;
    cursor: pointer;

    .workitem-close-x {
      display: block;
      transform: translateY(3px);
    }
  }

  .item-liner {
    //  padding-top: 1em;
    border-bottom: 1px black solid;
    //margin-bottom: 1em;
  }

  .item-liner-top {
    //  padding-top: 1em;
    border-bottom: 3px black solid;
    margin-bottom: .5em;
  }

  .item-liner-top2 {
    padding-top: .5em;
    border-bottom: 1px black solid;
    margin-bottom: .5em;
  }

  .item-liner-bottom {
    padding-top: .5em;
    border-bottom: 1px black solid;
  }

  .item-liner-bottom-spacer {
    padding-bottom: .3em;
  }

  .item-liner-bottom2 {
    border-top: 1px black solid;
    padding-bottom: .5em;
  }

  .item-liner-video {
    margin-top: .5em;
    border-bottom: 1px black solid;
    margin-bottom: .1em;
  }