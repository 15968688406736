.contact-mail-text-wrapper {
  transform: translateY(-100%);
}

.contact-mail-text {
  overflow: hidden;
}


.you-title-liner-wrap {
  border-bottom: 1px solid black;
}

.contact-adress-text-wrapper,
.contact-adress-text-privacy {
  overflow: hidden;
}

.contact-adress-text-privacy {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: auto;
  align-content: flex-start;
}

.contact-adress-text-container {
  transform: translateY(-100%);
}