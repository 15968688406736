.home-motion-mask-container {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top-left-container {
    transform: scale(0);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: center;

    .ico {
      transform: translate(-50% -50%);
    }

  }

  .top-right-container {
    transform: scale(0);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 61;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: center;

    .ico {
      transform: translate(-50% -50%);
    }

  }

  .bottom-right-container {
    transform: scale(0);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 62;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: center;

    .ico {
      transform: translate(-50% -50%);
    }

  }

  .bottom-left-container {
    transform: scale(0);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 63;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: center;

    .ico {
      transform: translate(-50% -50%);
    }

  }

  .hor-line-half {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 59;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: stretch;

    .top-half,
    .bottom-half {
      flex: 1 1 auto;
      width: 100%;
      background-color: white;
    }

  }

  .vert-line-half {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: stretch;
    z-index: +23;

    .left-half,
    .right-half {
      flex: 0 0 auto;
      width: 50%;
      height: 100%;
      background-color: white;
    }
  }

  .logo-bapost-container {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 25%;
    right: 25%;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: auto;
    align-content: center;

    .logo-bapost {
      flex: 1 1 auto;
      width: 50%;
    }
  }


}