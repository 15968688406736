@media (min-width: 1024px) and (orientation: landscape) {


    .intro-anim-text {
        color: black;
        /*  color: greenyellow;*/
        font-size: 4em;
        line-height: .85em;
        padding-left: .1em;
        padding-right: .1em;
    }

    .for-slide-title-text {
        color: black;
        /*  color: greenyellow;*/
        text-transform: capitalize;
        font-size: 3em;
    }

}


@media (min-width: 1024px) and (orientation: portrait) {

    .intro-anim-text {
        color: green;
        font-size: 5em;
        line-height: .85em;
        padding-left: .1em;
        padding-right: .1em;
    }

    .for-slide-title-text {
        text-transform: capitalize;
        font-size: 5em;
        color: green;
    }
}