@media (min-width: 576px) and (orientation: landscape) {

    .intro-anim-text {
        /*  color: orange;*/
        font-size: 3em;
        line-height: .85em;
        padding-left: .1em;
        padding-right: .1em;
    }

    .for-slide-title-text {

        /*  color: orange;*/
        text-transform: capitalize;
        font-size: 2.5em;
    }
}

@media (min-width: 576px) and (orientation: portrait) {

    .intro-anim-text {
        /*  color: yellow;*/
        font-size: 5em;
        line-height: .85em;
        padding-left: .1em;
        padding-right: .1em;
    }

    .for-slide-title-text {
        /*  color: yellow;*/
        text-transform: capitalize;
        font-size: 5em;
    }
}