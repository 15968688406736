@keyframes animLineTop {
  0% {
    transform: translateX(104%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes animTextTitleSection {
  0% {
    transform: translateY(104%);

  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animsubWorkTextTitleSection {
  0% {
    transform: translateX(115%);

  }

  100% {
    transform: translateX(0);
  }
}

@keyframes animLinethin {
  0% {
    transform: translateX(-104%);
  }

  100% {
    transform: translateX(0);
  }
}

.navbar-liner {
  animation-name: animLineTop;
  animation-delay: .1s;
  animation-duration: .4s;
  animation-fill-mode: both;
}

.section-title-text {
  animation-name: animTextTitleSection;
  animation-delay: .6s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.section-title-liner {
  animation-name: animLinethin;
  animation-delay: .5s;
  animation-duration: .4s;
  animation-fill-mode: both;
}

.you-title-liner-wrap {
  animation-name: animLinethin;
  animation-delay: .5s;
  animation-duration: .4s;
  animation-fill-mode: both;
}

.work-search-text-container {
  animation-name: animsubWorkTextTitleSection;
  animation-delay: 1.2s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.section-subtitle-container {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: auto;
  align-content: flex-end;
  justify-self: flex-end;
}

.section-subtitle-text {
  animation-name: animsubWorkTextTitleSection;
  animation-delay: 1.2s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.home-video-link {
  animation-name: animTextTitleSection;
  animation-delay: 1.2s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

/* ANIM FOR */

@keyframes animForTitle {
  0% {
    transform: translateY(104%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animForSubtitle {
  0% {
    transform: translateY(-104%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes animForLine {
  0% {
    transform: translateX(104%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes animForLine2 {
  0% {
    transform: translateX(-104%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes animForVideoWork {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.for-slide-title-text {
  animation-name: animForTitle;
  animation-delay: 1.2s;
  animation-duration: .5s;
  animation-fill-mode: both;

}

.for-spacer-scroll-line {
  animation-name: animForLine;
  animation-delay: .8s;
  animation-duration: .5s;
  animation-fill-mode: both;

}

.for-slide-subtitle-text {
  animation-name: animForSubtitle;
  animation-delay: 1.2s;
  animation-duration: .5s;
  animation-fill-mode: both;
}


/* Work Item */

.item-liner-top {
  animation-name: animForLine;
  animation-delay: .8s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.item-liner-top2 {
  animation-name: animForLine2;
  animation-delay: 1.1s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.detail-item-title-wrapper {
  animation-name: animForSubtitle;
  animation-delay: 1.3s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.item-liner-bottom {
  animation-name: animForLine;
  animation-delay: 1.1s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.item-liner-bottom2 {
  animation-name: animForLine2;
  animation-delay: .8s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.video-item {
  animation-name: animForVideoWork;
  animation-delay: 1.5s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

.workitem-content-container {
  animation-name: animForVideoWork;
  animation-delay: 1.5s;
  animation-duration: .5s;
  animation-fill-mode: both;
}