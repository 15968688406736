@media (min-width: 1200px) {

  .intro-anim-text {
    color: black;
    /*  color: blue;*/
    font-size: 6em;
    line-height: .85em;
    padding-left: .1em;
    padding-right: .1em;
  }

  .for-slide-title-text {
    color: black;
    /*  color: blue;*/
    text-transform: capitalize;
    font-size: 5em;
  }

}