@keyframes animPrivacy {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.privacy-text {
  animation: animPrivacy 500ms ease-out;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  text-align: justify;
  text-justify: inter-word;
  padding-right: 5px;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      animation-delay: 500ms + (100ms * $i);
    }
  }
}