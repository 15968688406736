.video-container {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .home-video-player,
  .home-video-player-a {
    //opacity: 0.5;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 0;
  }
}

.video-container {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .home-video-player {
    // transform: translateX(25%);
    opacity: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    left: 0;
    right: 0;
    top: 0;
  }
}

.video-invisible {
  opacity: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
}

@keyframes animForVideoHome {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.home-video-player-a {
  animation-name: animForVideoHome;
  animation-delay: .1s;
  animation-duration: .5s;
  animation-fill-mode: both;

}