@keyframes taadaa {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.SimpleVideo {
  position: fixed;
  z-index: +20;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: center;

  .video-player-moto {
    animation-name: taadaa;
    animation-delay: .3s;
    animation-duration: .3s;
    animation-fill-mode: both;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;

    //max-width:1920px;
    //  object-fit: contain;
    // object-position: center;
    //  transform: scale(1, 1);

  }
}

$button-size: 60px;
$blue: #3498db;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}





.Wrapper {
  margin: 0 auto;
  position: relative;
}

.SimpleVideo {
  position: relative;
}

.SimpleVideo-video {
  width: 100%;
  display: block;
}

.loader-container {
  right: 50%;

  top: 50%;
  position: absolute;
  transform: translate(30px, -30px);

  .SimpleVideo-loader {
    z-index: 500;
    // animation: spin 1s linear infinite;
    //border-radius: 50%;
    //border: $button-size * 0.05 solid rgba(255, 255, 255, 0.8);
    // border-left-color: transparent;
    // color: transparent;
    content: '';
    display: block;
    height: $button-size * 0.9;


    width: $button-size * 0.9;
  }
}

.timer-container {
  position: absolute;
  z-index: +1;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: center;

  .timer-clock {
    font-size: 5em;
  }
}