:root {
  --margin: 6px;
}

body,
#app,
#app,
#root {
  overflow: hidden;
  border: 0;
  padding: 0;
  margin: 0;
  min-height: 100%;
  min-height: stretch;
  min-height: -webkit-fill-available;
  display: flex;

}

html {
  height: stretch;
  height: -webkit-fill-available;
}

#root {
  flex-grow: 1;
  min-height: 100%;
}

body {
  font-size: 1.3em;
  letter-spacing: -0.04em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: black;
}

a {
  all: unset;
  cursor: pointer;
}

a.active {
  text-decoration: line-through;
}



.home-video-link {
  padding-right: .1em;
  font-size: 1.1em;
  font-weight: 400;
}

.navbar-a-logo {
  letter-spacing: -0.07em;
  font-size: 1.2em;
}

.top-spacer {
  line-height: 1.9em;
}

.navbar-container {
  width: calc(100% - (var(--margin)*2));
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: auto;
  align-content: flex-start;
}

.navbar-text {
  font-size: 1.55em;
  letter-spacing: -0.04em;
  transition: 1s;
}

.for-title-container,
.for-subtitle-container {
  overflow: hidden;
}

.work-subtitle-text,
.client-text,
.for-slide-subtitle-text,
.section-content-text,
.adress-text {
  padding-top: .3em;
  font-weight: 400;
  transform: translateY(-100%);

}

.normal-text {
  font-weight: 400;
}

.work-client-text,
.link-text,
.highlight-text {
  font-weight: 600;
}

.home-container {
  position: absolute;
  z-index: 1;
  height: 100%;
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  z-index: +1;
}

.section-title-text {
  font-size: 2.5em;
  letter-spacing: -0.05em;
  text-transform: capitalize;
}

.section-title-liner-wrap {
  display: block;
  border-top: 1px solid black;
  margin-bottom: var(--margin);

}

.section-subtitle-container {
  text-align: right;
  flex: 1 1 auto;
}

.section-subtitle-text {
  text-transform: capitalize;
  font-size: 1.1em;
  letter-spacing: -0.04em;
  font-weight: 400;
  padding-right: .1em
}

.section-workitem-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.works-list-container {
  overflow-y: scroll;
  padding-right: 2px;
  margin-bottom: var(--margin);
  overflow-x: hidden;
}

.work-item-preview-ico {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.work-list-ico {}

.work-title-link {
  font-size: 1.4em;
  line-height: .9em;
  letter-spacing: -.05em;
}

.contact-bottom-container {
  width: calc(100% - (var(--margin)*2));
  z-index: +1;
  background-color: white;
  position: absolute;
  padding-right: var(--margin);
  padding-left: var(--margin);
  bottom: var(--margin);
}

.video-container-wrapper-1 {
  position: absolute;
  z-index: +1;
  width: calc(100% - (var(--margin)*2));
  height: calc(100% - 5em);
  padding-right: var(--margin);
  padding-left: var(--margin);
  bottom: var(--margin);
}

.video-container-wrapper-2 {
  position: absolute;
  z-index: +3;
  width: calc(100% - (var(--margin)*2));
  height: calc(100% - 5em);
  padding-right: var(--margin);
  padding-left: var(--margin);
  bottom: var(--margin);
}


.section-contact-container {}


.work-iconos-container {
  line-height: 1.35em;
  font-size: 1.2em;
}

.work-preview-text {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.1em;
  letter-spacing: -.05em;
  vertical-align: middle;
}

.work-preview-bar {
  font-weight: 500;
  letter-spacing: -.05em;
  vertical-align: middle;
}

.section-error-container {}

.preview-ico {
  margin-right: .2em;
}

.navbar-liner {
  z-index: 7;
  margin-left: var(--margin);
  margin-right: var(--margin);
  border-bottom: 3px solid black;
  width: 100%;
  height: 10px;
  padding-top: 1.4em;
  pointer-events: none;
}

.for-slide-title-text {
  text-transform: capitalize;
  line-height: 1.15em;
}

.slide-title-text {
  font-size: 1em;
  line-height: .9em;
}

.slide-subtitle-text {
  font-size: 1.25em;
}

.manifesto-number-text {
  font-size: 4em;
}

.manifesto-title-text {
  font-size: 4em;
}

.manifesto-subtitle-text {
  font-size: 1.3em;
  padding: .2em;
}

/* content sections */
.section-container {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: flex-start;
  overflow: hidden;
}

.section-content-with-scroll-container {
  margin-left: var(--margin);
  padding-right: 2px;
  width: calc(100% - var(--margin));
  /* teninendo en cuenta 6px var */
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: auto;
  align-content: flex-start;
}

.section-content-container {
  margin-left: var(--margin);
  padding-right: var(--margin);
  flex: 0 0 auto;
  width: calc(100% - (var(--margin)*2));
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: auto;
  align-content: flex-start;
}

.section-title-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: auto;
  align-content: flex-start;
  background-color: white;
}

.section-title-container-scroll {
  margin-right: var(--margin);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: auto;
  align-content: flex-start;
  background-color: white;
}

.section-title-liner-wrap-scroll {
  margin-right: var(--margin);
  border-top: 1px solid black;
  margin-bottom: var(--margin);
}

.section-for-container,
.section-slide-container,
.section-privacy-container {

  margin-bottom: var(--margin);
  height: 100%;
  overflow-x: hidden;
}

.section-slide-item-wrapper {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: auto;
  align-content: center;
  overflow: hidden;
}

.section-slide-item-container {
  display: block;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: auto;
  align-content: flex-start;
  /* assuming var padding */
  padding-right: 2px;
}



/*Pantallas*/
@import url("css/280.css");
@import url("css/360.css");
@import url("css/576.css");
@import url("css/1024.css");
@import url("css/1200.css");

@media (min-width: 280px) and (orientation: portrait) {
  body {
    font-size: .9em;
  }
}


@media (min-width: 360px) and (orientation: portrait) {
  body {
    font-size: 1em;
  }
}

@media (min-width: 360px) and (orientation: landscape) {
  body {
    font-size: 1em;
  }
}


@media (min-width: 576px) and (orientation: portrait) {
  body {
    font-size: 1.1em;
  }
}

@media (min-width: 576px) and (orientation: portrait) {
  body {
    font-size: 1.1em;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  body {
    font-size: 1.2em;
  }
}

@media (min-width: 1024px) and (orientation: portrait) {
  body {
    font-size: 1.2em;
  }
}

@media (min-width: 1200px) {

  body {
    font-size: 1.3em;

  }
}

/* animaciones */
@import url("css/animations.css");

/* custom scrollbar */

/* para fadout opacity para descrubrir scroll */
@keyframes animForScrollFadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.scroll-mask {
  position: absolute;
  z-index: +10000;
  background-color: white;
  right: 0;
  bottom: 0;
  height: calc(100% - 5em);
  padding: 3px;
  animation-name: animForScrollFadeIn;
  animation-delay: 1.2s;
  animation-duration: .5s;
  animation-fill-mode: both;
}


::-webkit-scrollbar {
  width: 4px;
  margin-left: 5px
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #b3b3b3;

}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 5px;
  border: 5px solid transparent;
  animation-name: animForScrollbar;
  animation-delay: 1.2s;
  animation-duration: .5s;
  animation-fill-mode: both;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #727272;
}